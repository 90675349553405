
import { Link } from "react-router-dom";
import BasicLayout from "../../components/BasicLayout";
import Breadcrumb from "../../components/Breadcrumb";
import ContainerCenter from "../../components/ContainerCenter";
import HeroImage from "../../components/HeroImage";
import { RelevanteDokumente } from "../../components/RelevanteDokumente";
import Section from "../../components/Section";
import TKText from "../../components/TKText";
import img_Beruf_und_Privates from '../../images/LGBTQPlus/beruf_und_privates.png';
import img_Hero from '../../images/LGBTQPlus/BOSS_Women.jpg';
import img_Frauen_in from '../../images/LGBTQPlus/Female_Colleague.jpg';
import img_FemaleEmpowerment from '../../images/LGBTQPlus/female_empowerment.jpg';
import img_WOW from '../../images/LGBTQPlus/Logo_WOW.png';
import img_Infographic from '../../images/LGBTQPlus/Wheel_Boss.png';
import { RelevantDocument } from "../../types/RelevantDocument";
import links from "../links";
import { SideLinks } from "./VielfaltAlsChancePage";


export const Documents: RelevantDocument[] = [
    {
        title: "Gendergerechte Sprache",
        size: "3,2 MB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=afa66c94-b96b-422d-b726-6fa8e78cdfc2"
    },
    {
        title: "Leitfaden für Führungskräfte zu Mutterschutz, Elternzeit und Wiedereinstieg",
        size: "439 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=ce0b134f-ca7f-41dd-afb0-935a63f9d40a"
    },
    {
        title: "Female Empowerment",
        size: "962 KB",
        url: "https://steeleurope.sharepoint.com/sites/steel-stream/_layouts/15/viewer.aspx?sourcedoc=73b0e9f5-9c55-43fc-bb9f-009a96b24a04"
    },
];
export default function FraueninFuehrungspositionenPage(props: {}): JSX.Element {

    return (
        <BasicLayout>
            <ContainerCenter>
                <HeroImageSection />
                <Breadcrumb items={[
                    <Link to={links["Führungsverständnis stärken"]}>Führungsverständnis stärken</Link>,
                    <Link to={links["LGBTQ+_Frauen in Führungspositionen"]}>Frauen in Führungspositionen</Link>,
                ]} />

                <FrauenInFeuhrungspositionenSection />
                <FemaleEmpowermentSection />
                <WowSteelSection />
                <BerufUndPrivatesSection />
                <DocumentsSection />
            </ContainerCenter>
        </BasicLayout>
    );
}


function HeroImageSection() {
    return (
        <Section className="mb-8" >
            <HeroImage image={img_Hero} alt="Führungs- & Leistungskultur" />
        </Section>
    )
}

function FrauenInFeuhrungspositionenSection() {
    return (
        <Section className="mb-20">
            <div className="grid grid-cols-12 gap-3 p-4 lg:p-0">
                <div className="col-span-12 pr-8 lg:col-span-9">
                    <TKText.Header>
                        Wie Frauen die Zukunft des Managements gestalten
                    </TKText.Header>
                    <TKText.SubHeader>
                        Wenn wir nach den Besten suchen, berücksichtigen wir die spezifischen Fähigkeiten von Männern und Frauen gleichermaßen und bieten beiden Geschlechtern gleiche Entwicklungsmöglichkeiten. Wir unterstützen Frauen dabei, ihren gleichberechtigten Platz in der Karrierewelt zu finden, und arbeiten kontinuierlich daran, die Rahmenbedingungen so zu gestalten, dass sie diesen Platz auch einnehmen können.
                    </TKText.SubHeader>
                    <div className="grid grid-cols-12 gap-4 mt-4">
                        <div className="col-span-7 pr-20">
                            <p className="mb-6 text-sm font-tk-medium">
                                Bei Steel sind 8,4 % der Belegschaft weiblich, ca. 12 % der Frauen sind in Führungspositionen (A-L3).
                            </p>
                            <p className="mb-6 text-sm font-tk-medium text-tk-brand">
                                Unser vorrangiges Ziel ist es, Frauen in Führungs-positionen gezielt zu erhöhen.
                            </p>
                            <p className="mb-2 text-sm font-tk-medium">
                                Dies messen wir durch zwei Zielzahlen:
                            </p>
                            <ul className="list-disc list-inside marker:text-tk-brand">
                                <li className="mb-6">
                                    Bis zum GJ 24/25 müssen mindestens 9,6 % der Frauen in Führungspositionen (A-L3) tätig sein. Dieses Ziel haben wir bereits überschritten.
                                </li>
                                <li>
                                    Die SE-AG ist dem zweiten Führungspositionen- Gesetz verpflichtet und hat verbindliche Zielzahlen von Frauen in Führungspositionen definiert. Bis zum 30.06.2027 müssen 33% des Aufsichtsrates, 20% des Vorstandes, 15 % der ersten und 15 % der zweiten Ebene unterhalb des Vorstands weiblich besetzt sein.
                                </li>
                            </ul>

                        </div>
                        <div className="flex col-span-5 justify-end">
                            <div>
                                <img src={img_Frauen_in} alt='' className="w-full" />
                            </div>
                        </div>
                    </div>
                    <p className="mt-6 mb-6 text-sm">
                        Wir setzen gezielte Maßnahmen um, um den Frauenanteil in unserem Unternehmen, insbesondere in Führungspositionen, zu erhöhen. Dazu fördern wir eine vielfältige Rollenverteilung und bieten geschlechterunabhängige Möglichkeiten zur besseren Vereinbarkeit von Beruf und Privatleben. Wir passen unsere HR-Instrumente und -Maßnahmen an, um vom Recruiting über die Entwicklung und Beförderung bis hin zu flexiblen Arbeitsmodellen und Beschäftigungsbedingungen optimale Bedingungen zu schaffen.
                    </p>
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <SideLinks />
                    <div className="flex justify-center mt-10">
                        <img src={img_Infographic} alt='' className="mx-auto w-full" />
                    </div>
                </div>
            </div>
        </Section >
    )
}

function FemaleEmpowermentSection() {
    return (
        <Section className="mt-20">
            <TKText.Header>
                Female Empowerment bei Steel
            </TKText.Header>
            <div className="grid grid-cols-12 gap-20">
                <div className="col-span-8">
                    <TKText.SubHeader>
                        Female Empowerment steht für die gezielte Förderung und<br /> Stärkung von Frauen am Arbeitsplatz.
                    </TKText.SubHeader>
                    <TKText.Body>
                        Es umfasst Maßnahmen zur Chancengleichheit, die Unterstützung bei der beruflichen Entwicklung, den Abbau von geschlechtsspezifischen Barrieren und die Schaffung einer inklusiven Unternehmenskultur, in der Frauen ihre Potenziale voll entfalten können. Ziel ist es, Frauen zu ermutigen, Führungspositionen anzustreben, sich selbstbewusst einzubringen und gleiche Karrierechancen zu erhalten wie ihre männlichen Kollegen.
                    </TKText.Body>
                    <a href="https://www.thyssenkrupp-steel.com/de/karriere/arbeiten-bei-thyssenkrupp-steel-europe/was-uns-auszeichnet/female-empowerment/content-page-44.html"
                        target="_blank"
                        className="inline-block px-4 pt-1.5 pb-0.5 text-lg border border-tk-brand" rel="noreferrer">
                        Mehr zu Female Empowerment
                    </a>
                </div>
                <div className="col-span-4">
                    <img src={img_FemaleEmpowerment} alt='' />
                </div>
            </div>

        </Section>
    )
}

function WowSteelSection() {
    return (
        <Section className="mt-20">
            <TKText.Header>
                wow!@steel – das Frauennetzwerk
            </TKText.Header>
            <div className="grid grid-cols-12 gap-20">
                <div className="col-span-8">
                    <TKText.SubHeader>
                        Starke Netzwerke sind ein Erfolgsfaktor – nicht nur für die Belegschaft, sondern auch für Unternehmen! Deshalb gibt es bereits seit 2013 das konzernweite Frauennetzwerk wow! Web of women. Bei Steel gibt es das Sub-Netzwerk WoW!@Steel.
                    </TKText.SubHeader>
                    <TKText.Body>
                        Wow!@steel bringt weibliche Fach- und Führungskräfte bei Steel zusammen und bietet regelmäßigen und offenen Austausch. Dieser ermöglicht es uns, weibliche Talente sichtbar zu machen, voneinander zu lernen, einander zu helfen, uns gegenseitig Feedback zu geben und uns zu motivieren. Gemeinsam arbeiten wir an spannenden Projekten und Formaten und stärken die Vielfalt bei Steel und die Chancen von Frauen auf der Hütte.
                    </TKText.Body>
                    <TKText.Body>
                        Bist du eine weibliche Fach- oder Führungskraft und möchtest dich aktiv bei wow!@steel engagieren oder mehr über die Arbeit von wow! erfahren, dann melde dich gerne direkt bei den Koordinatorinnen.
                    </TKText.Body>
                    <a href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/meine-programme-und-initiativen/diversity-management/mitarbeitenden-netzwerke-bei-steel"
                        target='_blank'
                        className="inline-block px-4 pt-1.5 pb-0.5 text-lg border border-tk-brand"
                        rel="noreferrer">
                        Zum Netzwerk wow@steel
                    </a>
                </div>
                <div className="col-span-4">
                    <img src={img_WOW} alt='' />
                </div>
            </div>

        </Section>
    )
}

function BerufUndPrivatesSection() {
    return (
        <Section className="mt-20">
            <div className="grid grid-cols-12 gap-20">
                <div className="col-span-8">
                    <TKText.Header>
                        Beruf und Privates unter einen Hut bringen: nicht nur ein Frauenthema
                    </TKText.Header>
                    <TKText.SubHeader>
                        Familienfreundlichkeit ist nicht nur ein Frauenthema – es betrifft uns alle.
                    </TKText.SubHeader>
                    <TKText.Body>
                        Immer mehr Väter nehmen Elternzeit, Frauen kommen nach einigen Monaten aus der Elternzeit zurück oder arbeiten Teilzeit in Elternzeit.
                    </TKText.Body>
                    <TKText.Body>
                        Egal ob Kinderbetreuung, Schülernachhilfe, Pflegeunterstützung oder flexible Arbeitsmodelle: Unser Unternehmen schafft die Rahmenbedingungen, damit jede*r Beruf und Familie erfolgreich vereinen kann. Denn nur, wenn wir gemeinsam Verantwortung übernehmen, können wir ein Arbeitsumfeld schaffen, in dem alle Mitarbeitenden ihr volles Potenzial entfalten können.
                    </TKText.Body>
                    <a href="https://steel-net.thyssenkrupp-steel.com/de/mitarbeiterthemen/beruf-privat/familienfreundlichkeit"
                        target="_blank"
                        className="inline-block px-4 pt-1.5 pb-0.5 text-lg border border-tk-brand"
                        rel="noreferrer">
                        Familienfreundlichkeit im Steel.net
                    </a>
                </div>
                <div className="col-span-4">
                    <img src={img_Beruf_und_Privates} alt='' />
                </div>
            </div>

        </Section>
    )
}

export function DocumentsSection() {
    return (
        <div className="grid grid-cols-12 gap-3 p-4 mt-20 lg:p-0">
            <div className="col-span-12 lg:col-span-8">
                <RelevanteDokumente docs={[Documents[2]]} title="" subtitle='Informationen' />
                <RelevanteDokumente docs={[Documents[0]]} title="" subtitle='Gendergerechte Sprache' />
                <RelevanteDokumente docs={[Documents[1]]} title="" subtitle='Handlungshilfe' />
            </div>
            <div className="hidden lg:col-span-1 lg:block">
            </div>
            <div className="col-span-12 lg:col-span-3">

            </div>
        </div>
    )
}
